import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { IconX, IconChevronDown } from '@tabler/icons-react';
import { cn } from '../lib/utils';
import { Option } from "../pages/UserProfileBuilder";

type TypeAheadMultiSelectProps = {
  options: Option[];
  answer?: Option[];
  className?: string;
  setAnswer: (answer: Option[]) => void;
  canCreate?: boolean;
};

const TypeAheadMultiSelect: React.FC<TypeAheadMultiSelectProps> = ({
  options,
  answer = [],
  className,
  setAnswer,
  canCreate = false,
}) => {
  const [allOptions, setAllOptions] = useState<Option[]>(options);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(answer);
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const nextId = useRef<number>(options.length + 1); // Simple ID generator

  useEffect(() => {
    setAllOptions(options);
  }, [options]);

  useEffect(() => {
    // Filter options based on input and exclude selected options
    const lowerInput = inputValue.toLowerCase();
    const availableOptions = allOptions.filter(
      (item) =>
        !selectedOptions.some((selected) => selected.id === item.id) &&
        item.name.toLowerCase().includes(lowerInput)
    );
    setFilteredOptions(availableOptions);
  }, [inputValue, allOptions, selectedOptions]);

  useEffect(() => {
    setAnswer(selectedOptions);
  }, [setAnswer, selectedOptions])

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleSelectOption = (item: Option) => {
    setSelectedOptions((prev) => [...prev, item]);
    setInputValue('');
    setIsDropdownOpen(false);
    inputRef.current?.focus();
  };

  const handleRemoveOption = (item: Option) => {
    setSelectedOptions((prev) => prev.filter((selected) => selected.id !== item.id));
  };

  const handleCreateOption = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue === '') return;

    // Check if the option already exists
    const exists = allOptions.some(
      (option) => option.name.toLowerCase() === trimmedValue.toLowerCase()
    );

    if (exists) {
      // Option exists, select it
      const existingOption = allOptions.find(
        (option) => option.name.toLowerCase() === trimmedValue.toLowerCase()
      );
      if (existingOption) {
        handleSelectOption(existingOption);
      }
    } else {
      // Create a new option
      const newOption: Option = {
        id: `new-${nextId.current}`,
        name: trimmedValue,
      };
      nextId.current += 1;
      setAllOptions((prev) => [...prev, newOption]);
      handleSelectOption(newOption);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (filteredOptions.length > 0) {
        handleSelectOption(filteredOptions[0]);
      } else if (canCreate) {
        handleCreateOption();
      }
    }
    if (e.key === 'Escape') {
      setIsDropdownOpen(false);
    }
  };

  return (
    <div className={cn('w-full', className)} ref={dropdownRef}>
      <div className="border border-gray-300 rounded px-3 py-2 flex flex-wrap items-center gap-2">
        {selectedOptions.map((item) => (
          <div
            key={item.id}
            className="flex items-center bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-sm"
          >
            {item.name}
            <button
              type="button"
              onClick={() => handleRemoveOption(item)}
              className="ml-1 text-blue-500 hover:text-blue-700 focus:outline-none"
            >
              <IconX size={16} />
            </button>
          </div>
        ))}
        <input
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsDropdownOpen(true)}
          className="flex-grow focus:outline-none text-sm bg-transparent"
          placeholder="Select or create options..."
        />
        <IconChevronDown
          size={20}
          className="text-gray-500 cursor-pointer"
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
            inputRef.current?.focus();
          }}
        />
      </div>
      {isDropdownOpen && (filteredOptions.length > 0 || (canCreate && inputValue.trim() !== '')) && (
        <ul className="border border-gray-300 border-t-0 max-h-60 overflow-y-auto rounded-b-md bg-white">
          {filteredOptions.map((item) => (
            <li
              key={item.id}
              onClick={() => handleSelectOption(item)}
              className="px-3 py-2 hover:bg-blue-100 cursor-pointer"
            >
              {item.name}
            </li>
          ))}
          {canCreate && inputValue.trim() !== '' && !allOptions.some(
            (option) => option.name.toLowerCase() === inputValue.trim().toLowerCase()
          ) && (
            <li
              onClick={handleCreateOption}
              className="px-3 py-2 hover:bg-blue-100 cursor-pointer italic text-gray-600"
            >
              Create "{inputValue.trim()}"
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default TypeAheadMultiSelect;
