import { IconLoader2 } from "@tabler/icons-react";

const ZenLoading = () => {
    return(
        <div className="flex flex-col justify-center items-center">
            <div className="relative">
                <img src="LotusZenbeli.png" alt="Zenbeli Logo" className="z-0 w-[28px] h-[18.383px] absolute top-[19px] left-[14px] bottom-0 right-0"/>
                <IconLoader2 className="animate-spin z-1 stroke-1" size={56} />

            </div>
        </div>
    )
}

export default ZenLoading;