// Import styled from emotion
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import DefaultSidebar from '../components/Navbar';
import { useUser } from '../hooks/useUser';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('bg-Zenbeli.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); /* Adjust opacity to desired darkness */
        z-index: 0; /* Ensure the overlay is behind the content */
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

const Landing = () => {
    const userApi = useUser();
    const loggedIn = userApi.state === 'LoggedIn';
    const survey_completed = loggedIn && userApi.user?.completed_user_survey;
    
    return(
        <Container>
            <Content>
            <DefaultSidebar hamburgerClassName={"text-white"} />
                <div className="self-start w-2/3 md:w-1/3">
                    <img src="ZenBeli-sub-app_white-green_01.png" alt="Zenbeli Logo" />
                </div>
                <div className="p-6 md:p-16 text-left font-museo-sans flex items-start flex-col gap-8">
                    <h3 className="text-2xl md:text-4xl">Welcome to Zenbeli</h3>
                    <h1 className="text-lg">Zenbeli is a revolutionary approach to food-centered health</h1>
                    {survey_completed ? 
                        <Link
                            to="zenbeli_digestive_assistant"
                            className="shadow-[0_0_0_3px_#fff_inset] px-6 py-2 bg-transparent border border-white text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                            Go to Dashboard
                        </Link>
                    : 
                        <Link 
                            to="user-profile-builder" 
                            className="shadow-[0_0_0_3px_#fff_inset] px-6 py-2 bg-transparent border border-white text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                            Start Assessment
                        </Link>
                    }
                </div>
            </Content>
        </Container>
    )
}


export default Landing;