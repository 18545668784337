import { createContext, useCallback, useLayoutEffect } from "react";
import { UserProfile } from "../api/ZenbeliBackendApi";
import { LoadingStateStrict } from "../helper";
import Cookies from 'js-cookie';
import { useZenbeliBackend } from "./useZenbeliBackend";
import useLocalStorage from "./useLocalStorage";
import React from "react";


type UserState = {
  state: "loggedIn",
  user: LoadingStateStrict<UserProfile>
} | {
  state: "loggedOut",
  unmounted: boolean,
};

type UserAPI = {
  state: "LoggedIn",
  user: UserProfile
} | {
  state: "LoggedOut",
  updateUserInfo: () => void
}

const UserContext = createContext<UserAPI>({ state: "LoggedOut", updateUserInfo: () => { 
  console.error("Not inside UserProvider")
} });

/**
 * Due to useLocalStorage, this must be a singleton
 * TODO: Upgrade useLocalStorage to allow for multiple instances
 */
export const UserProvider: React.FC<{ children: React.ReactNode, mountingScreen: React.ReactNode }> = (props) => {
  const [user, setUser] = useLocalStorage<UserState>("user", { state: "loggedOut", unmounted: true });
  const db = useZenbeliBackend();

  const handleUserRetrieve = useCallback(() => db.endpoints.api.userProfileRetrieve()
    .then((result) => setUser({ state: "loggedIn", user: { status: "loaded", data: result.data.user } }))
    .catch(() => setUser(() => ({ state: "loggedOut", unmounted: false }))),
    [db.endpoints.api.userProfileRetrieve, setUser]);


  useLayoutEffect(() => {
    // This is sorta incorrect since a csrf isn't a check for a logged in user, but it's a good enough check for now
    if (Cookies.get('csrftoken')) {
      if (user.state === "loggedOut") {
        setUser({ state: "loggedIn", user: { status: "loading", data: null } });
      }
      handleUserRetrieve()
    } else {
      db.endpoints.api.csrfTokenCreate()
      setUser({
        state: "loggedOut",
        unmounted: false,
      })
    }
  }, []);

  if (user.state === "loggedOut" && user.unmounted) {
    return <>{props.mountingScreen}</>;
  }

  console.log("User state", user)
  return (
    <UserContext.Provider value={((userState: UserState): UserAPI => {
      const user: UserProfile | null = userState.state === "loggedIn" ? userState.user.data : null;
      if (!user) return { state: "LoggedOut", updateUserInfo: handleUserRetrieve };
      return { state: "LoggedIn", user };
    })(user)}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => React.useContext(UserContext);