import React, { createContext, useMemo } from "react";
import { ZenbeliBackendApi } from "../api/ZenbeliBackendApi";
import axios from 'axios';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "https://api." + window.location.hostname.replace(/^www\./, '');


axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withXSRFToken = true;


type ZenbeliInterface = {
  endpoints: ZenbeliBackendApi<unknown>
}


const ZenbeliContext = createContext<ZenbeliInterface>({ endpoints: new ZenbeliBackendApi<unknown>({ baseURL: BACKEND_URL, withCredentials: true, xsrfCookieName: "csrftoken", xsrfHeaderName: "X-CSRFTOKEN" }) });

const useZenbeliBackend = () => {
  const result = React.useContext(ZenbeliContext);
  if (result === null) throw new Error("Zenbeli is null. either not set up yet or needs to be used in backend provider");
  return result;
};

const ZenbeliBackendProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const endpoints = useMemo(() => {
    const result = new ZenbeliBackendApi<unknown>({ baseURL: BACKEND_URL, withCredentials: true, xsrfCookieName: "csrftoken", xsrfHeaderName: "X-CSRFTOKEN" });
    return result;
  }, []);


  return <ZenbeliContext.Provider value={{ endpoints: endpoints }}>
    {props.children}
  </ZenbeliContext.Provider>
}


export { useZenbeliBackend, ZenbeliBackendProvider }