import axios from 'axios';
import Cookies from 'js-cookie';
import { BACKEND_URL, useZenbeliBackend } from './useZenbeliBackend';

axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withXSRFToken = true;


export const useLogout: () => () => Promise<any> = () => () => axios
  .post(`${BACKEND_URL}/api/logout/`, null,
    { headers: { 'X-CSRFToken': Cookies.get('csrftoken') } }
  )
  .then(() => {
    window.location.reload();
  })

export const useLogin: () => (email: string, password: string) => Promise<{
  success: true;
} | {
  success: false;
  error: any;
}> = () => {
  const db = useZenbeliBackend();

  return (email, password) => {
    return db.endpoints.api
      .loginCreate(
        { email, password },
        { withCredentials: true, headers: { 'X-CSRFToken': Cookies.get('csrftoken') } })
      .then(() => ({ success: true } as const))
      .catch(error => {
        console.error(error);
        return { success: false, error: error } as const;
      });
  }
}

export const useSignup = () => {
  const db = useZenbeliBackend();

  const signup = (email: string, first_name: string, last_name: string, password: string) => {
    return db.endpoints.api
      .signupCreate(
        { email, first_name, last_name, password },
        { withCredentials: true, headers: { 'X-CSRFToken': Cookies.get('csrftoken') } })
      .then(() => ({ success: true, error: '' } as const))
      .catch(error => {
        console.error(error)
        return { success: false, error: error } as const
      });
  }
  return signup;
}