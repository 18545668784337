import { HoverEffect } from "./ui/card-hover-effect";
import { QuestionComponentProps } from "../pages/UserProfileBuilder";



const IconButtonSelection = ({options, setAnswer, answer}: QuestionComponentProps) => {
    return(
        <div className=" font-museo-sans w-full flex flex-row flex-wrap gap-4 justify-center items-center pb-4">
            {options.map((option, index) => {
                return(
                    <div key={index} className="flex justify-center items-center">
                        <button onClick={() => setAnswer(option)} className={`w-36 h-36 p-3 border border-zenbeliGreen/50 bg-zenbeliGreen/20 rounded-md flex justify-center items-center ${answer === option ? 'drop-shadow-zenbeliGreen' : ''}`}>
                            <div className="text-sm break-words w-full">
                                {option.name}
                            </div>
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default IconButtonSelection;