import { useNavigate } from "react-router-dom";
import DefaultSidebar from "../components/Navbar";
import { useEffect, useState, useRef, useCallback } from "react";
import { useZenbeliBackend } from "../hooks/useZenbeliBackend";
import ZenLoading from "../components/ZenLoading";
import Markdown from 'react-markdown'
import styled from '@emotion/styled';
import { IconCandyOff, IconChefHat, IconDownload, IconFridge, IconMedicalCross, IconReport, IconUser } from "@tabler/icons-react";

//Get  REACT_APP_BACKEND_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000";

const ActionButton = styled.div<{ selected?: boolean, disabled?: boolean }>`
    @media (max-width: 768px) {
        font-size: .75rem;
        width: 6rem;
    }

    ${props => !!props.disabled ? "opacity: 0.5; pointer-events: none;" : ""}

    ${props => !!props.selected ? "border-bottom: 2px solid #94eb00;" : "border-bottom: 1px solid #e2e8f0;"}

    text-wrap: none;
    flex-direction: column;
    
    text-align: center;
    padding: .25rem;
    display: flex;
    gap: .5rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: #f7fafc;
        border-bottom: 1px solid #e2e8f0;
    }
`

const UserProfile = ({ user }: { user: any }) => {
    return (
        <>
            <div>
                {user.diagnosed_conditions && <><span className="text-bold">Diagnosed Conditions:</span> {
                    user.diagnosed_conditions.map((condition: any) => {
                        return (
                            <div>
                                {condition.name}
                            </div>
                        )
                    })
                }</>}
            </div>

            <div className="flex flex-col gap-1">
                {user.symptoms && <><span className="text-bold">Symptoms:</span> {
                    user.symptoms.map((symptom: any) => {
                        return (
                            <div>
                                {symptom.name}
                            </div>
                        )
                    })
                }</>
                }
            </div>


            {user.severity_of_symptoms && <div><span className="text-bold">Severity of Symptoms:</span> {user.severity_of_symptoms}</div>}
            {user.impact_on_life_quality && <div><span className="text-bold">Impact On Quality Of Life:</span> {user.impact_on_life_quality}</div>}
            {user.comfort_level && <div><span className="text-bold">Comfort In Social Situations:</span> {user.comfort_level}</div>}
            {user.food_intolerances && <div className="flex gap-1 flex-col"><span className="text-bold">Food Intolerances:</span> {
                user.food_intolerances.map((food: any) => {
                    return (
                        <div>
                            {food.name}
                        </div>
                    )
                })
            }</div>
            }
            {user.current_diet_management && <div><span className="text-bold">Current Diet Management:</span> {user.current_diet_management}</div>}


        </>
    )
}

const ZenbeliDigestiveAssistant = () => {
    const [userData, setUserData] = useState<any>()
    const db = useZenbeliBackend()
    const navigate = useNavigate()
    const bottomScroll = useRef<HTMLDivElement | null>(null);
    const [tab, setTab] = useState<string>("user_profile")
    const [reportLoading, setReportLoading] = useState<boolean>(false)
    const [reports, setReports] = useState<any>({})
    const [loadingUserReports, setLoadingUserReports] = useState<boolean>(true)




    useEffect(() => {
        db.endpoints.api.userProfileRetrieve().then((result: any) => setUserData({ state: "loggedIn", user: { status: "loaded", data: result.data.user } }))
            .catch(() => navigate("/"))
    }, [])

    useEffect(() => {
        console.log("Running Poll!")
        pollUserReports()
    }, [])


    // dynamically call tab method from teh tab variable
    const POSSIBLE_REPORTS = ["what_to_eat", "what_to_avoid", "generate_recipies", "more_about_condition", "generate_medical_report"];

    const getUserReports = async () => {
        const result = await db.endpoints.api.userReportsRetrieve();
        return result.data;
    };

    const pollUserReports = async () => {
        console.log("Poll User Reports")

        const new_reports = await getUserReports() as any;
        console.log("Report", new_reports);
        // Check if all reports are available
        if (!POSSIBLE_REPORTS.every((report) => new_reports[report])) {
            console.log("Reports are not complete");
            setTimeout(pollUserReports, 5000);
        }else{
            setLoadingUserReports(false)
        }

        setReports(new_reports);
    }
    
    




    // const prompt = async (report_type: string) => {

    //     try {
    //         const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1]; // Get CSRF token from cookies
    //         const response = await fetch(BACKEND_URL + '/api/prompt/', { // Adjust the URL as needed
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'X-CSRFToken': csrfToken || '', // Include CSRF token
    //             },
    //             credentials: 'include', // Include credentials (cookies) in the request
    //             body: JSON.stringify({ report_type: report_type }),
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         const reader = response.body?.getReader();
    //         if (!reader) throw new Error("No reader")

    //         const decoder = new TextDecoder('utf-8');
    //         let done = false;
    //         let messageContent = "";

    //         while (!done) {
    //             const { value, done: doneReading } = await reader.read();
    //             done = doneReading;
    //             if (value) {
    //                 const chunkValue = decoder.decode(value, { stream: true });
    //                 console.log('Received chunk:', chunkValue);
    //                 messageContent += chunkValue;
    //                 // You can update the UI here with the new chunk
    //                 setLLMResponse(messageContent)
    //                 // Scroll to the bottom of the chat
    //                 if (bottomScroll.current)
    //                     bottomScroll.current?.scrollIntoView({ behavior: "smooth" });
    //             }
    //         }

    //         console.log('Final message content:', messageContent);
    //     } catch (error) {
    //         console.error('Error fetching stream:', error);
    //     }
    // }

    const what_to_eat = () => {
        const tab = "what_to_eat"
        setTab(tab)
    }

    const what_to_avoid = () => {
        const tab = "what_to_avoid"
        setTab(tab)
    }

    const generate_recipies = () => {
        const tab = "generate_recipies"
        setTab(tab)
    }

    const more_about_condition = () => {
        const tab = "more_about_condition"
        setTab(tab)
    }

    const generate_medical_report = () => {
        const tab = "generate_medical_report"
        setTab(tab)
    }

    const downloadPDF = useCallback(async () => {
        setReportLoading(true)
        try {
            // Use the fetch API to get the PDF as a Blob
            const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1]; // Get CSRF token from cookies
            const response = await fetch(BACKEND_URL + '/api/pdf_export/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken || '', // Include CSRF token
                },
                credentials: 'include', // Include credentials (cookies) in the request
                body: JSON.stringify({ report_type: tab })
            });

            // Check if the response is okay
            if (!response.ok) {
                throw new Error("Failed to fetch PDF");
            }

            // Get the response as a Blob
            const blob = await response.blob();

            // Create an object URL from the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element, set the download attribute, and trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = "digestive_assistant.pdf";
            document.body.appendChild(a);
            a.click();

            // Cleanup: Remove the link and revoke the object URL
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Failed to download the PDF:", error);
        }
        setReportLoading(false)
    }, [tab]);


    if (!userData) return <div className="p-8"><ZenLoading /></div>



    const ActionBar = () => {
        return (
            <div className="flex gap-2 py-4 sticky w-full bg-white">
                <ActionButton selected={tab === "user_profile"} onClick={() => setTab("user_profile")}>
                    <IconUser />
                    View Your Data
                </ActionButton>
                <ActionButton disabled={!reports["what_to_eat"]} selected={tab === "what_to_eat"} onClick={what_to_eat}>
                    <IconFridge />
                    What To Eat
                </ActionButton>
                <ActionButton disabled={!reports["what_to_avoid"]} onClick={what_to_avoid} selected={tab === "what_to_avoid"}>
                    <IconCandyOff />
                    What To Avoid
                </ActionButton>
                <ActionButton disabled={!reports["generate_recipies"]} onClick={generate_recipies} selected={tab === "generate_recipies"}>
                    <IconChefHat />
                    Generate Recipies
                </ActionButton>
                <ActionButton disabled={!reports["more_about_condition"]} onClick={more_about_condition} selected={tab === "more_about_condition"}>
                    <IconReport />
                    More About Condition
                </ActionButton>
                <ActionButton disabled={!reports["generate_medical_report"]} onClick={generate_medical_report} selected={tab === "generate_medical_report"}>
                    <IconMedicalCross />
                    Generate Medical Report
                </ActionButton>
            </div>
        )
    }

    const user = userData.user.data
    console.log(user)
    return (<div className="flex flex-col gap-2 p-8 text-left">
        <div className="flex justify-between flex-row">
            <DefaultSidebar />
            <div className="flex flex-row gap-2 justify-center items-center">
                <span>{user.user.first_name} {user.user.last_name}</span>
                <img src="LotusZenbeli.png" alt="Zenbeli Logo" className="w-[33px] h-[21.3px]" />
            </div>
        </div>
        <ActionBar />
        {loadingUserReports && <div className="font-bold">User Reports Processing</div>}
        {
            tab !== "user_profile" && <div className="w-full flex flex-row gap-5">
                <button onClick={downloadPDF} className="p-2 flex justify-center items-center gap-1">Download Report <IconDownload className="h-[20px] w-[20px] inline" /></button>
                {reportLoading && <ZenLoading />}
            </div>
        }

        {
            tab === "user_profile" ? 
                <UserProfile user={user} /> : 
                (
                    reports && reports[tab] ? 
                    <Markdown>{reports[tab].content}</Markdown> :
                    <ZenLoading />
                )
        }
        <div ref={bottomScroll}></div>
    </div>)
}

export default ZenbeliDigestiveAssistant;