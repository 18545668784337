import Modal from 'react-modal';
import { LoginForm } from './LoginForm';
import { cn } from '../lib/utils';


const LoginModal = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: (v: boolean) => void }) => {

    // TODO Make modal common component with x to close
    return (<>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => (setIsOpen(false))}
        style={{
          overlay: {
            background: 'transparent',
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            border: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'fit-content',
            height: 'auto',
            inset: 'auto',
            background: 'transparent',
            // Mobile media query
            // @ts-ignore
            '@media (max-width: 768px)': {
              padding: '1rem',
            },
            zIndex: 1000
          }
        }}
      >
        <div css={cn('bg-white')}>
          <LoginForm successCallback={() => setIsOpen(false)} />
        </div>
      </Modal>
    </>
    )
  }
  

export default LoginModal;